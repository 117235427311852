import React from "react"
import Layout from "../components/layout"

export default function FindUs() {
  return ( 
    <Layout>
    <section className="container">
        <h1>Map &amp; Directions</h1>
        <div className="row mb-2">
            <div className="col-md-8">
            	<iframe className="google-map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1176.6407676956942!2d-1.5881634626673466!3d53.85564151252389!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0000000000000000%3A0xa17dc86c38f75e63!2sAdel+War+Memorial!5e0!3m2!1sen!2suk!4v1459456866442" frameBorder="0" allowFullScreen></iframe>
            </div>
            <div className="col-md-4">
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div className="col p-4 d-flex flex-column position-static">
                        <h3 className="lcj-color">Club Address</h3>
                        <address>
                            <strong>Leeds City Juniors FC</strong>
                            <p>Adel War Memorial Association<br />
                            Church Lane, Adel<br />
                            Leeds<br />
                            West Yorkshire<br />
                            LS16 8DE
                            </p>
                        </address>
                    </div>
                </div>
                <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
                    <div className="col p-4 d-flex flex-column position-static">
                        <h3>Email Us</h3>
                        <address>
                            <strong><a href="mailto:leedscityjnrs@gmail.com">leedscityjnrs@gmail.com</a></strong>
                        </address>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </Layout>
  );
}

